.footer-container {
    height: 17.8%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin: 0 0 0 0;
}

.footer-parent {
    width: 100%;
    height: 100px;
    background-image: url(../../../assets/Home/shape-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    display: block;
}

.footer-parent img {
    width: 100%;
    max-width: 100%;
    height: 130px;
}

@media only screen and (max-width: 1110px) {
    .footer-parent {
        display: flex;
        align-items: flex-end;
    }
}